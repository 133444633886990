import Vue from "vue/dist/vue.min";
import Vuex from "vuex";

import domLoaded from "../../../js/_helpers/domLoaded";

Vue.use(Vuex);

import store from "../../../store/index";

const SupportSearch = () => import(/* webpackChunkName: "SupportSearch" */ "./SupportSearch.vue");

function init() {
    const supportSearch = document.querySelectorAll('[data-vue="support-search"]');

    if (!supportSearch) return;

    supportSearch.forEach((el) => {
        return new Vue({
            el,
            store,
            components: { SupportSearch },
        });
    });
}

domLoaded(init);
